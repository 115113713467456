import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { NarrowContainer } from "../components/container";
import Img from "gatsby-image";
import styled from "styled-components";
import { HtmlContent } from "../components/html-content";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { ContactTemplateQuery } from "../../graphql-types";

const ContactPage: FC<PageProps<ContactTemplateQuery>> = (props) => {
  const { data } = props;

  return (
    <Layout>
      <SEO title="Contact" />
      <NarrowContainer>
        <HtmlContent
          dangerouslySetInnerHTML={{ __html: data.wpPage?.content || "" }}
          style={{ paddingTop: "3em" }}
        />

        <iframe
          src="https://cms.decoart.be/contactformulier/"
          frameBorder="0"
          style={{ width: "100%", height: 650, border: 0 }}
          allowFullScreen={true}
        ></iframe>
      </NarrowContainer>

      <InfoBox style={{ marginTop: "3em" }}>
        <InfoSectionContainer>
          <InfoSection>
            <h3>Gegevens</h3>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wp?.globaal?.globalCustomFields?.address || "",
                }}
              />
              <div style={{ marginTop: 30 }}>
                Tel.:{" "}
                <a
                  href={`tel:${data.wp?.globaal?.globalCustomFields?.phoneNumber}`}
                >
                  {data.wp?.globaal?.globalCustomFields?.phoneNumber}
                </a>
              </div>
              <div>
                Fax.:{" "}
                <a
                  href={`fax:${data.wp?.globaal?.globalCustomFields?.faxNumber}`}
                >
                  {data.wp?.globaal?.globalCustomFields?.faxNumber}
                </a>
              </div>
              <div>
                <a
                  href={`mailto:${data.wp?.globaal?.globalCustomFields?.email}`}
                >
                  {data.wp?.globaal?.globalCustomFields?.email}
                </a>
              </div>
              <p>
                <a
                  href={data.wp?.globaal?.globalCustomFields?.facebookUrl || ""}
                  style={{ display:"block", borderBottom: "1px solid #fff", width: "max-content" }}
                  target="_new"
                >
                  Facebook
                </a>
                <a
                  href={data.wp?.globaal?.globalCustomFields?.instagramUrl || ""}
                  style={{ display:"block", borderBottom: "1px solid #fff", width: "max-content" }}
                  target="_new"
                >
                  Instagram
                </a>
                <a
                  href={data.wp?.globaal?.globalCustomFields?.linkedinUrl || ""}
                  style={{ display:"block", borderBottom: "1px solid #fff", width: "max-content" }}
                  target="_new"
                >
                  LinkedIn
                </a>
              </p>
            </div>
          </InfoSection>

          <InfoSection>
            <h3>Openingstijden</h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.wp?.globaal?.globalCustomFields?.openingHours || "",
              }}
            />
          </InfoSection>

          <InfoSection>
            <h3>
              Sluiting {data.wp?.globaal?.globalCustomFields?.closedDates?.year}
            </h3>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.wp?.globaal?.globalCustomFields?.closedDates?.dates ||
                    "",
                }}
              />
            </div>
          </InfoSection>
        </InfoSectionContainer>
      </InfoBox>

      <NarrowContainer style={{ marginTop: "4em", paddingBottom: "2em" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2495.4719198966704!2d4.413890316092333!3d51.284038979599146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c409c9aaaad075%3A0xf9ee39d98b7cc526!2sDeco%20Art!5e0!3m2!1snl!2snl!4v1570622849678!5m2!1snl!2snl"
          frameBorder="0"
          style={{ width: "100%", height: "50vh", border: 0 }}
          allowFullScreen={true}
        ></iframe>
      </NarrowContainer>
    </Layout>
  );
};

export default ContactPage;

const InfoBox = styled.div`
  background-color: #00a88e;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;

  h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 0;
  }
`;

const InfoSectionContainer = styled(NarrowContainer)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

const InfoSection = styled.div`
  border-bottom: 1px solid #29b6a0;
  padding: 20px;

  :last-child {
    border-bottom: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-bottom: none;
    border-left: 1px solid #29b6a0;
    width: 33%;

    :last-child {
      border-right: 1px solid #29b6a0;
    }
  }
`;

const ProjectImage = styled(Img)`
  max-width: 100%;
  max-height: 100%;
`;

export const query = graphql`
  query ContactTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    wp {
      globaal {
        globalCustomFields {
          address
          phoneNumber
          faxNumber
          email
          openingHours
          facebookUrl
          instagramUrl
          linkedinUrl
          closedDates {
            year
            dates
          }
        }
      }
    }
  }
`;
